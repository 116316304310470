import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import bgImage from '../assets/img/blurred-image-1.jpg';
import ImageContact from '../assets/img/emploi.jpg';

const JobApplicationForm = () => {
  const [jobTitle, setJobTitle] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [error, setError] = useState('');

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setAttachments(files);
  };
  const isFormValid = () => {
    return jobTitle && firstName && lastName && phone && email && message;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      setError('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    setError('');

    try {
      const templateParams = {
        jobTitle,
        firstName,
        lastName,
        phone,
        email,
        message,
        attachments,
      };

      if (attachments.length > 0) {
        templateParams.attachments = attachments;
      }
      console.log('Avant envoi', attachments)
      const response = await emailjs.send(
        'service_pxtczyz',
        'template_7k0irbk',
        templateParams,
        '1xQtduIT5CLSugC_O'
      );
      // console.log('Contenue du mail', templateParams)
      setJobTitle('');
      setFirstName('');
      setLastName('');
      setPhone('');
      setEmail('');
      setMessage('');
      setAttachments([]);

      alert(`Merci ${firstName} ! Votre candidature a bien été envoyée ! Mais votre pièce jointe n'a pas été transmise, merci de l'envoyer à l'adresse:        ass-rh@mondistri.re!`);
      // console.log('Formulaire soumis avec succès !', attachments, templateParams, response);
    } catch (error) {
      setError("Erreur lors de l'envoi du formulaire.");
      // console.error('Erreur lors de l\'envoi du formulaire:', error);
    }
  };

  return (
    <div className="container"
    style={{
      backgroundImage: `url(${bgImage})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",}}>
       <div className="row">
        <div className="col-lg-6 col-md-6">
          <div className="section-header pt-5 pb-5 text-center">
            <h3 className="section-title"><span>Je </span>Postule</h3>
            <h6 className="section-subtitle mx-auto">
              Remplissez le formulaire pour déposer votre candidature. Les champs * sont obligatoirs
            </h6>
            </div>
            <form id="jobApplicationForm" className="mb-5" onSubmit={handleSubmit}>
        <div className="mb-3">
        <label className="block-label" htmlFor="poste">
                *Poste
              </label>
          <select className="form-control"
                placeholder="Poste ..."
                id="poste"
                onChange={(e) => setJobTitle(e.target.value)} value={jobTitle} required>
            <option value="">Sélectionnez un poste</option>
            <option value="Responsable d'Agence (H/F) - Le Port">Responsable d'Agence (H/F) - Le Port</option>
            <option value="Responsable d'Agence (H/F) - Saint Pierre">Responsable d'Agence (H/F) - Saint Pierre</option>
            <option value="Responsable Commercial(e) - Dép Éclairage">Responsable Commercial(e) - Dép Éclairage</option>
            <option value="Technico Commercial(e) Itinérant - Zone Sud">Technico Commercial(e) Itinérant - Zone Sud</option>
            <option value="Technico Commercial(e) Itinérant - Zone Ouest">Technico Commercial(e) Itinérant - Zone Ouest</option>
            <option value="Technico Commercial(e) Courant Faible Itinérant">Technico Commercial(e) Courant Faible Itinérant</option>
            <option value="Vendeur(se) Comptoir - Zone Sud">Vendeur(se) Comptoir - Zone Sud</option>
            <option value="Magasinier livreur (H/F) - Saint André">Magasinier livreur (H/F) - Saint André</option>
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="name">
                *Nom
              </label>
              <input
                className="form-control"
                placeholder="Votre Nom ..."
                type="text"
                id="name"
                onChange={(e) => setLastName(e.target.value)} value={lastName} required
              />
              <label className="form-label" htmlFor="lastname">
                *Prénom
              </label>
              <input
                className="form-control"
                placeholder="Votre Prénom ..."
                type="text"
                id="lastname"
                onChange={(e) => setFirstName(e.target.value)} value={firstName} required
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="phone">
                *N° Téléphone
              </label>
              <input
                className="form-control"
                placeholder="Votre Numéro ..."
                type="tel" onChange={(e) => setPhone(e.target.value)} value={phone}
                id="phone"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="email">
                *Email
              </label>
              <input
                className="form-control"
                placeholder="Votre Email ..."
                type="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)} value={email} required
              />
            </div>
        <div className="mb-3">
          <label className="form-label">Pièces jointes</label>
          <input className="form-control"
                id="cv"
                type="file" multiple onChange={handleFileChange} />
        </div>
        <div className="mb-3">
          <label className="form-label">Message :</label>
          <textarea className="form-control"
                style={{ height: "200px" }}
                placeholder="Votre message ..."
                id="message"
                onChange={(e) => setMessage(e.target.value)} value={message} required />
        </div>
          <button className="btn btn-danger mt-5" type="submit"disabled={!isFormValid()}>Envoyer
          </button>
      </form>
      {error && <div className="alert alert-danger">{error}</div>} 
      </div>
      <div className="col-lg-6 col-md-6">
          <div
            className="d-none d-md-block d-lg-block  pb-5"
            style={{
              backgroundImage: `url(${ImageContact})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "100vh",}}>
          </div>
          <div className="d-md-none">
            <img src={ImageContact} alt="Background" style={{ width: "100%", height: "auto" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobApplicationForm;
