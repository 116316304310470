import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const DownloadPage = () => {
  const handleDownload = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };

  return (
    <main
      id="catalogues"
      initial={{ opacity: 0, y: "-100px" }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 2.5 }}
      style={{ backgroundColor: `#D5F2EE` }}
    >
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h3 className='section-title'><span>Nos </span> Téléchargements</h3>
          <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={200} duration={2}>
            <h6 className='section-subtitle mx-auto ml-auto'>
              Vous souhaitez ouvrir un compte chez nous! Téléchargez directement vos documents.
            </h6>
          </ScrollAnimation>
        </div>
        <div className="catalogue-grid">
          <div className="mb-3">
            <h6>Ouverture de compte & CGV</h6>
            <button className="btn btn-dark mt-2" onClick={() => handleDownload('https://documents.mondistri.re/edited_2024_10_ELECTRIC PLUS_ LA REUNION_Courrier_C800 V1.pdf')}>Télécharger</button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default DownloadPage;
