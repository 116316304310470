import React, { Component } from 'react';
import { CCarousel, CCarouselItem, CImage } from '@coreui/react';

import Img1 from '../../assets/img/2024_13_DIGITAL_EPR_DESTOCKAGE.gif';

class Carousel extends Component {
  constructor(props) {
    super(props);

    this.AUTOCHANGE_TIME = 4000;
    this.carouselRef = React.createRef();

    this.state = {
      activeSlide: 0,
    };
  }

  componentDidMount() {
    this.startAutoChange();
  }

  componentWillUnmount() {
    this.stopAutoChange();
  }

  startAutoChange() {
    this.carouselInterval = setInterval(() => {
      this.nextSlide();
    }, this.AUTOCHANGE_TIME);
  }

  stopAutoChange() {
    clearInterval(this.carouselInterval);
  }

  nextSlide() {
    if (this.carouselRef.current && typeof this.carouselRef.current.next === 'function') {
      this.carouselRef.current.next();
      this.updateActiveSlide();
    }
  }

  prevSlide() {
    if (this.carouselRef.current && typeof this.carouselRef.current.prev === 'function') {
      this.carouselRef.current.prev();
      this.updateActiveSlide();
    }
  }

  updateActiveSlide() {
    const totalSlides = this.carouselRef.current && this.carouselRef.current._slides.length;

    const nextSlideIndex = (this.state.activeSlide + 1) % totalSlides;

    this.setState({ activeSlide: nextSlideIndex });
  }

  render() {
    return (
      <section id='Carousel' className='carousel-behind-navbar'>
        <div className='container pb-2'>
          <div className='section-header pt-2 pb-5 text-center'>
            <CCarousel
            >
              <CCarouselItem>
                <CImage className='d-block w-100 rounded-4' src={Img1} alt='Férmeture annuel 2023' />
              </CCarouselItem>
            </CCarousel>
          </div>
        </div>
      </section>
    );
  }
}

export default Carousel;
