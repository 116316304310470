import React, { useState, useEffect } from 'react';

const CablesDetails = () => {
    const [images, setImages] = useState([
  
    {
      id: 1,
      url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAQQpxgsHHUhourBgYEEJSwfTsDIAi7VnHJg&usqp=CAU',
      title:'Nous proposons une vaste gamme de câbles isolés en cuivre et en aluminium, disponibles dans différentes sections pour répondre à vos besoins spécifiques.',
      description: "Nous proposons une vaste gamme de câbles isolés en cuivre et en aluminium, disponibles dans différentes sections pour répondre à vos besoins spécifiques. Pour les câbles en aluminium, nous proposons des sections allant de 16 mm² à 630 mm². Tous nos câbles isolés sont fabriqués selon les normes de qualité les plus élevées pour  garantir une isolation fiable et une transmission efficace de l'électricité. Que vous ayez besoin de câbles pour vos projets résidentiels, commerciaux ou industriels, notre gamme variée répondra à vos exigences en matière de puissance et de sécurité électrique. N'hésitez pas à nous contacter pour obtenir des informations sur d'autres sections disponibles dans notre large gamme de câbles isolés en cuivre et en aluminium.",
      direction: 'right',
      isVisible: false,
    },
    {
      id: 2,
      url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQl1jhUxKVcvrMy7caGR-EmNPAyW9k81LZdcg&usqp=CAU',
      title:'Nous proposons une vaste gamme de préfilé pour répondre à vos besoins spécifiques.',
      description: "Découvrez notre gamme étendue de fils préfabriqués Préfilé, une solution innovante et pratique pour toutes vos installations électriques. Conçue par des experts de l'industrie, notre gamme vous permet de simplifier et d'accélérer l'installation dans vos projets résidentiels, commerciaux et industriels. Chaque fil est fabriqué avec des matériaux de qualité supérieure, assurant une conductivité optimale, une durabilité accrue et une résistance aux intempéries. Sécurité garantie, nos produits respectent les normes les plus strictes, vous offrant une installation électrique conforme et fiable. Trouvez le câble parfait parmi notre vaste sélection pour répondre à vos besoins spécifiques, du standard aux applications spéciales. Economisez temps et argent avec notre gamme Préfilé sans compromis sur la qualité et la sécurité. Contactez-nous dès maintenant pour en savoir plus sur notre gamme complète de fils préfabriqués.",
      direction: 'left',
      isVisible: false,
    },
    {
      id: 3,
      url: 'https://www.socamont.com//boutique/images_produits//6700-cable-reseau-cat6-futp-1x4-z.jpg',
      title:'Nous proposons une vaste gamme de câbles reseau pour répondre à vos besoins spécifiques.',
      description: "Découvrez notre gamme variée de câbles réseau de qualité supérieure, conçus par des experts de l'industrie pour simplifier et accélérer vos projets de connectivité. Chaque câble est fabriqué avec des matériaux de haute qualité, assurant une conductivité optimale, une durabilité accrue et une résistance aux conditions difficiles. La sécurité est notre priorité, nos produits respectent les normes les plus strictes pour une connectivité fiable. Trouvez le câble idéal parmi notre sélection, du standard aux options spécialisées. Économisez temps et argent tout en bénéficiant d'une qualité et d'une sécurité sans compromis. Contactez-nous dès maintenant pour en savoir plus sur notre gamme complète de câbles réseau.",
      direction: 'right',
      isVisible: false,
    },
    {
      id: 4,
      url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTteKLj3czZitvL-64-K9lEhp_XJ9SSxUEseyfndh9SU4I2RrucHOVjlupiGLbX93I0ePY&usqp=CAU',
      title:'Nous proposons une vaste gamme de câbles CR1 pour répondre à vos besoins spécifiques.',
      description: "Découvrez notre gamme variée de câbles CR1 de haute qualité, une solution innovante et pratique pour la sécurité incendie dans vos projets résidentiels, commerciaux et industriels. Fabriqués avec des matériaux de première qualité, nos câbles PIRO offrent une résistance exceptionnelle aux hautes températures et une faible émission de fumée, assurant une protection accrue. Sécurité garantie, nos câbles PIRO respectent les normes strictes de sécurité incendie. Trouvez le câble PIRO adapté à vos besoins parmi notre sélection. Contactez-nous pour en savoir plus sur notre gamme complète de câbles PIRO et renforcez la protection incendie de vos projets.",
      direction: 'left',
      isVisible: false,
    },
    
  ]);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleImageClick = (id) => {
    setSelectedImageId(id);
    setModalOpen(true);
  };
  const closeModal = () => {
    setSelectedImageId(null);
    setModalOpen(false);
  };

  useEffect(() => {
    let currentImageIndex = 0;
    const intervalId = setInterval(() => {
      setImages((prevImages) => {
        const updatedImages = prevImages.map((image, index) => ({
          ...image,
          isVisible: index === currentImageIndex ? true : image.isVisible,
        }));
        currentImageIndex++;
        if (currentImageIndex >= images.length) {
          clearInterval(intervalId);
        }
        return updatedImages;
      });
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const middleIndex = Math.ceil(images.length / 2);
  const leftImages = images.slice(0, middleIndex);
  const rightImages = images.slice(middleIndex);

  return (
    <main
    id="cables"
      initial={{ opacity: 0, y: "-100px" }}
      animate={{ opacity: 10, y: 0 }}
      transition={{ duration: 2.5 }}
      style={{ backgroundColor: `#E9ECEF` }}
    >
    <div className="container bg-body-secondary ">
      {/* Section d'en-tête */}
      <div className="section-header pt-5 pb-5 text-center">
        <h3 className="section-title">
          <span>Nos </span>Câbles
        </h3>
        <h6 className="section-subtitle mx-auto">
          N'hésitez pas à nous contacter pour toute demande, commentaire ou question que vous pourriez avoir.
        </h6>
      </div>

      {/* Contenu principal */}
      <div className="section-content p-5">
        <div className="row">
          <div className="col-lg-6">
            {leftImages.map((image) => (
              <div
                key={image.id}
                style={{
                  textAlign: image.direction,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                onMouseEnter={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: true }
                        : prevImage
                    )
                  )
                }
                onMouseLeave={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: false }
                        : prevImage
                    )
                  )
                }
              >
                <img
                  src={image.url}
                  alt={`Image ${image.id}`}
                  onClick={() => handleImageClick(image.id)}
                  style={{
                    width: image.isVisible ? '250px' : '200px', 
                    height: image.isVisible ? '250px' : '200px',
                    borderRadius: '50%',
                    padding: '2%',
                    opacity: 1,
                    transition: 'width 0.5s, height 0.5s',
                    cursor: 'pointer',
                  }}
                />
                {image.isVisible && <p>{image.title}</p>}
              </div>
            ))}
          </div>
          <div className="col-lg-6">
            {rightImages.map((image) => (
              <div
                key={image.id}
                style={{
                  textAlign: image.direction,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                onMouseEnter={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: true }
                        : prevImage
                    )
                  )
                }
                onMouseLeave={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: false }
                        : prevImage
                    )
                  )
                }
              >
                <img
                  src={image.url}
                  alt={`Image ${image.id}`}
                  onClick={() => handleImageClick(image.id)}
                  style={{
                    width: image.isVisible ? '250px' : '200px',
                    height: image.isVisible ? '250px' : '200px',
                    borderRadius: '50%',
                    padding: '2%',
                    opacity: 1,
                    transition: 'width 0.5s, height 0.5s',
                    cursor: 'pointer',
                  }}
                />
                {image.isVisible && <p>{image.title}</p>}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Fenêtre modale */}
      {isModalOpen && selectedImageId !== null && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img
              src={images.find((image) => image.id === selectedImageId)?.url}
              alt={`Image ${selectedImageId}`}
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '10px',
              }}
            />
            <p>{images.find((image) => image.id === selectedImageId)?.description}</p>
            <button className="close-button" onClick={closeModal}>
              &#x2715;
            </button>
          </div>
        </div>
      )}
    </div>
    </main>
  );
};

export default CablesDetails;
