import React, { useState, useEffect } from 'react';

const AppareillagesDetails = () => {
    const [images, setImages] = useState([

  
    {
      id: 1,
      url: 'https://www.legrand.com.eg/sites/g/files/ocwmcr501/files/collection_detail_2/Celiane.png',
      title:"Harmonie et Performance - Explorez Notre Gamme d'Appareillages Électriques pour un Design Raffiné et des Fonctionnalités Innovantes !",
      description: "Découvrez la perfection du design et de la technologie avec la gamme Céliane de Legrand. Conçue pour s'adapter à tous les styles d'intérieurs, cette collection d'appareillages électriques vous offre une esthétique raffinée et des fonctionnalités innovantes. Avec une variété de finitions élégantes et des matériaux de haute qualité, chaque interrupteur, prise, variateur, ou commande domotique devient une pièce d'exception qui complète harmonieusement votre décor. Profitez d'une installation facile et d'une fiabilité éprouvée, tout en bénéficiant des dernières avancées technologiques pour un contrôle intuitif de l'éclairage et des équipements électriques. Faites de votre espace de vie un lieu d'élégance et de confort avec Céliane de Legrand.",
      direction: 'left',
      isVisible: false,
    },
    {
      id: 2,
      url: 'https://www.legrand.se/sites/b2bse/files/homepage/Netatmo_products.jpg',
      title:"L'Avenir de la Maison Connectée - Découvrez Notre Gamme Domotique pour une Vie Intelligente et Personnalisée !",
      description: "Découvrez l'avenir de la maison connectée avec la gamme Netatmo. Conçue pour vous offrir une expérience de vie intelligente et personnalisée, cette collection d'appareillages domotiques vous permet de contrôler votre maison du bout des doigts. Grâce à des produits innovants tels que les thermostats intelligents, les caméras de sécurité extérieures, les détecteurs de qualité de l'air, et bien plus encore, Netatmo transforme votre foyer en un environnement sécurisé, confortable et écoénergétique. Avec une intégration harmonieuse aux assistants vocaux populaires et une application conviviale, vous avez le pouvoir de surveiller et de gérer votre maison à distance. Profitez d'une technologie de pointe, d'un design élégant et d'une facilité d'utilisation inégalée avec la gamme Netatmo, pour une maison plus intelligente et plus connectée que jamais.",
      direction: 'left',
      isVisible: false,
    },
    {
      id: 3,
      url: 'https://madvij.com/wp-content/uploads/2021/07/legrand-range-of-ectrical-products-500x500-1.jpg',
      title:"Découvrez Notre Gamme d'Appareillage Modulaire et Coffrets pour une Distribution Électrique Fiable et Évolutive !",
      description: "Découvrez l'excellence de vos installations avec la gamme d'appareillage modulaire et coffrets de Legrand. Conçue pour répondre aux besoins les plus exigeants en matière de distribution électrique, cette collection offre des solutions complètes et fiables pour sécuriser et contrôler l'électricité dans votre environnement résidentiel ou professionnel. Grâce à des produits modulaires intelligents tels que disjoncteurs, interrupteurs différentiels, contacteurs, et des coffrets électriques de différentes capacités, vous bénéficiez d'une flexibilité inégalée pour configurer votre installation électrique selon vos besoins spécifiques. Que ce soit pour une utilisation résidentielle, industrielle ou tertiaire, la gamme d'appareillage modulaire et coffrets de Legrand vous offre des solutions évolutives et conformes aux normes internationales.L'accent est mis sur la sécurité, la facilité d'installation et la durabilité, garantissant une exploitation sans faille de votre installation électrique sur le long terme. Optez pour la fiabilité et la performance avec la gamme d'appareillage modulaire et coffrets de Legrand, pour une électrification efficace et sereine de votre espace de vie ou de travail.",
      direction: 'left',
      isVisible: false,
    },
    {
      id: 4,
      url: 'https://auto.hwupgrade.it/immagini/bticino_ricarica_greenup_1_600.jpg',
      title:"La Révolution Verte de l'Électromobilité - Explorez Notre Gamme de Bornes de Recharge pour une Mobilité Durable et Pratique !",
      description: "Explorez la révolution verte de l'électromobilité avec la gamme Green'up de Legrand. Spécialement conçue pour faciliter la recharge de vos véhicules électriques, cette collection de bornes de recharge vous offre une solution pratique, sûre et efficace pour répondre à vos besoins en mobilité durable. Grâce à une technologie de pointe et une conception ergonomique, les bornes Green'up garantissent une recharge rapide et sécurisée de votre véhicule, tout en optimisant l'utilisation de l'énergie. Facile à installer, adaptée à tous les environnements et conforme aux normes internationales, la gamme Green'up est votre alliée pour une transition écologique réussie. Faites un pas vers un avenir plus propre et plus respectueux de l'environnement avec Green'up de Legrand.",
      direction: 'left',
      isVisible: false,
    },
    
  ]);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleImageClick = (id) => {
    setSelectedImageId(id);
    setModalOpen(true);
  };
  const closeModal = () => {
    setSelectedImageId(null);
    setModalOpen(false);
  };

  useEffect(() => {
    let currentImageIndex = 0;
    const intervalId = setInterval(() => {
      setImages((prevImages) => {
        const updatedImages = prevImages.map((image, index) => ({
          ...image,
          isVisible: index === currentImageIndex ? true : image.isVisible,
        }));
        currentImageIndex++;
        if (currentImageIndex >= images.length) {
          clearInterval(intervalId);
        }
        return updatedImages;
      });
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const middleIndex = Math.ceil(images.length / 2);
  const leftImages = images.slice(0, middleIndex);
  const rightImages = images.slice(middleIndex);

  return (
    <main
    id="appareillages"
      initial={{ opacity: 0, y: "-100px" }}
      animate={{ opacity: 10, y: 0 }}
      transition={{ duration: 2.5 }}
      style={{ backgroundColor: `#E9ECEF` }}
    >
    <div className="container bg-body-secondary ">
      {/* Section d'en-tête */}
      <div className="section-header pt-5 pb-5 text-center">
        <h3 className="section-title">
          <span>Nos </span>Appareillages<span> & </span>Protections
        </h3>
        <h6 className="section-subtitle mx-auto">
          N'hésitez pas à nous contacter pour toute demande, commentaire ou question que vous pourriez avoir.
        </h6>
      </div>

      {/* Contenu principal */}
      <div className="section-content p-5">
        <div className="row">
          <div className="col-lg-6">
            {leftImages.map((image) => (
              <div
                key={image.id}
                style={{
                  textAlign: image.direction,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                onMouseEnter={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: true }
                        : prevImage
                    )
                  )
                }
                onMouseLeave={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: false }
                        : prevImage
                    )
                  )
                }
              >
                <img
                  src={image.url}
                  alt={`Image ${image.id}`}
                  onClick={() => handleImageClick(image.id)}
                  style={{
                    width: image.isVisible ? '250px' : '200px',
                    height: image.isVisible ? '250px' : '200px',
                    borderRadius: '50%',
                    padding: '2%',
                    opacity: 1,
                    transition: 'width 0.5s, height 0.5s',
                    cursor: 'pointer',
                  }}
                />
                {image.isVisible && <p>{image.title}</p>}
              </div>
            ))}
          </div>
          <div className="col-lg-6">
            {rightImages.map((image) => (
              <div
                key={image.id}
                style={{
                  textAlign: image.direction,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                onMouseEnter={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: true }
                        : prevImage
                    )
                  )
                }
                onMouseLeave={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: false }
                        : prevImage
                    )
                  )
                }
              >
                <img
                  src={image.url}
                  alt={`Image ${image.id}`}
                  onClick={() => handleImageClick(image.id)}
                  style={{
                    width: image.isVisible ? '250px' : '200px',
                    height: image.isVisible ? '250px' : '200px',
                    borderRadius: '50%',
                    padding: '2%',
                    opacity: 1,
                    transition: 'width 0.5s, height 0.5s',
                    cursor: 'pointer',
                  }}
                />
                {image.isVisible && <p>{image.title}</p>}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Fenêtre modale */}
      {isModalOpen && selectedImageId !== null && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img
              src={images.find((image) => image.id === selectedImageId)?.url}
              alt={`Image ${selectedImageId}`}
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '10px',
              }}
            />
            <p>{images.find((image) => image.id === selectedImageId)?.description}</p>
            <button className="close-button" onClick={closeModal}>
              &#x2715;
            </button>
          </div>
        </div>
      )}
    </div>
    </main>
  );
};

export default AppareillagesDetails;
