import React, { useState, useEffect } from 'react';

const AlarmeDetails = () => {
    const [images, setImages] = useState([
  
    {
      id: 1,
      url: 'https://gagadget.com/media/post_big/ajax_1.jpg',
      title:"Sécurité Intelligente : Gamme de Hubs Ajax System, Le Cœur de Votre Système de Sécurité pour une Gestion Facile et Efficace !",
      description: "Découvrez la puissance de la sécurité intelligente avec les hubs Ajax System. Ces appareils avancés sont au cœur de votre système de sécurité, vous permettant de connecter et de contrôler facilement tous vos capteurs et appareils Ajax. Grâce à une technologie de pointe, les hubs assurent une communication fiable et sécurisée avec les détecteurs sans fil, les caméras et autres dispositifs du système. Vous pouvez surveiller votre maison ou votre entreprise à distance via l'application mobile intuitive, recevoir des notifications en temps réel en cas d'intrusion ou d'événements suspects, et réagir rapidement aux incidents. Avec une configuration simple, une conception élégante et des fonctionnalités évolutives, les hubs Ajax System offrent une sécurité de pointe et une tranquillité d'esprit totale pour protéger ce qui compte le plus pour vous.",
      direction: 'left',
      isVisible: false,
    },
    {
      id: 2,
      url: 'https://www.securitysales.com/wp-content/uploads/2023/01/Ajax-and-ADI-edit.png',
      title:"Surveillance Avancée : Gamme de Détecteurs de Mouvement Ajax System, Une Sécurité Inégalée pour Tous Vos Espaces !",
      description: "Explorez une détection de mouvement avancée avec les détecteurs de mouvement intérieurs et extérieurs d'Ajax System. Conçus pour assurer une sécurité inégalée, ces détecteurs utilisent des technologies de pointe pour détecter les mouvements suspects avec une grande précision. Certains modèles sont équipés d'une caméra intégrée pour capturer des images en cas d'activité suspecte, vous permettant de visualiser en temps réel ce qui se passe chez vous ou autour de votre propriété. Grâce à leur résistance aux intempéries, les détecteurs de mouvement extérieurs fonctionnent parfaitement dans toutes les conditions météorologiques, vous offrant une surveillance efficace à l'extérieur. Quant aux détecteurs de mouvement intérieurs, ils sont discrets et se fondent harmonieusement dans votre intérieur, garantissant une détection précise sans compromettre l'esthétique de votre espace. Profitez d'une sécurité de pointe, d'une gestion intuitive et d'une tranquillité d'esprit totale grâce aux détecteurs de mouvement d'Ajax System, que vous optiez pour la prise de photo ou non.",
      direction: 'left',
      isVisible: false,
    },
    {
      id: 3,
      url: 'https://support.ajax.systems/wp-content/uploads/2022/08/doorprotect-plus-8-0.jpg',
      title:"Sécurité Proactive : Gamme de Détecteurs d'Ouverture Ajax System, Protégez Vos Portes et Fenêtres !",
      description: "Explorez une sécurité avancée avec les détecteurs d'ouverture d'Ajax System. Conçus pour protéger vos portes et fenêtres, ces détecteurs surveillent en permanence les mouvements d'ouverture et de fermeture. Lorsqu'une ouverture non autorisée est détectée, le système déclenche instantanément une alerte, vous permettant de réagir rapidement en cas d'intrusion. Certains modèles sont équipés d'une fonction de prise de photo intégrée, capturant des images en temps réel lors d'une activation, pour vous offrir une preuve visuelle des événements. Grâce à leur design compact et sans fil, les détecteurs d'ouverture se fondent discrètement dans votre intérieur, préservant ainsi l'esthétique de vos espaces. Profitez d'une gestion intuitive via l'application mobile, recevez des notifications en temps réel et bénéficiez d'une tranquillité d'esprit totale en sachant que votre maison ou votre entreprise est protégée de manière proactive. Avec les détecteurs d'ouverture d'Ajax System, la sécurité de votre propriété est entre de bonnes mains.",
      direction: 'left',
      isVisible: false,
    },
    {
      id: 4,
      url: 'https://www.sicherheitstechnik-nord.de/onlineshop/media/image/product/67170/md/ajax-nebelmaschine-urfog-modular-line-ajax-ready-kabellos-fuer-300m~2.png',
      title:"Protection Instantanée : Gamme FOG d'Ajax System, La Solution Antivol Avancée !",
      description: "Découvrez une défense antivol inégalée avec la gamme FOG d'Ajax System. Spécialement conçue pour empêcher les vols et les intrusions, cette solution innovante utilise une technologie de brouillard dense pour désorienter et dissuader les intrus en quelques secondes seulement. Lorsqu'une tentative d'effraction est détectée, le générateur de brouillard libère un épais rideau de fumée qui réduit considérablement la visibilité à quelques centimètres, empêchant ainsi les voleurs d'agir et de localiser les biens de valeur. Avec une installation simple et un fonctionnement autonome, la gamme FOG offre une sécurité instantanée et efficace pour vos espaces commerciaux ou résidentiels. Protégez vos biens et assurez-vous une tranquillité d'esprit totale avec la gamme FOG d'Ajax System, l'alliée ultime contre les vols et les intrusions.",
      direction: 'left',
      isVisible: false,
    },
    
  ]);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleImageClick = (id) => {
    setSelectedImageId(id);
    setModalOpen(true);
  };
  const closeModal = () => {
    setSelectedImageId(null);
    setModalOpen(false);
  };

  useEffect(() => {
    let currentImageIndex = 0;
    const intervalId = setInterval(() => {
      setImages((prevImages) => {
        const updatedImages = prevImages.map((image, index) => ({
          ...image,
          isVisible: index === currentImageIndex ? true : image.isVisible,
        }));
        currentImageIndex++;
        if (currentImageIndex >= images.length) {
          clearInterval(intervalId);
        }
        return updatedImages;
      });
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const middleIndex = Math.ceil(images.length / 2);
  const leftImages = images.slice(0, middleIndex);
  const rightImages = images.slice(middleIndex);

  return (
    <main
    id="alarme"
      initial={{ opacity: 0, y: "-100px" }}
      animate={{ opacity: 10, y: 0 }}
      transition={{ duration: 2.5 }}
      style={{ backgroundColor: `#E9ECEF` }}
    >
    <div className="container bg-body-secondary ">
      {/* Section d'en-tête */}
      <div className="section-header pt-5 pb-5 text-center">
        <h3 className="section-title">
          <span>Nos </span>Alarmes
        </h3>
        <h6 className="section-subtitle mx-auto">
          N'hésitez pas à nous contacter pour toute demande, commentaire ou question que vous pourriez avoir.
        </h6>
      </div>

      {/* Contenu principal */}
      <div className="section-content p-5">
        <div className="row">
          <div className="col-lg-6">
            {leftImages.map((image) => (
              <div
                key={image.id}
                style={{
                  textAlign: image.direction,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                onMouseEnter={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: true }
                        : prevImage
                    )
                  )
                }
                onMouseLeave={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: false }
                        : prevImage
                    )
                  )
                }
              >
                <img
                  src={image.url}
                  alt={`Image ${image.id}`}
                  onClick={() => handleImageClick(image.id)}
                  style={{
                    width: image.isVisible ? '250px' : '200px',
                    height: image.isVisible ? '250px' : '200px',

                    padding: '2%',
                    opacity: 1,
                    transition: 'width 0.5s, height 0.5s',
                    cursor: 'pointer',
                  }}
                />
                {image.isVisible && <p>{image.title}</p>}
              </div>
            ))}
          </div>
          <div className="col-lg-6">
            {rightImages.map((image) => (
              <div
                key={image.id}
                style={{
                  textAlign: image.direction,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                onMouseEnter={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: true }
                        : prevImage
                    )
                  )
                }
                onMouseLeave={() =>
                  setImages((prevImages) =>
                    prevImages.map((prevImage) =>
                      prevImage.id === image.id
                        ? { ...prevImage, isVisible: false }
                        : prevImage
                    )
                  )
                }
              >
                <img
                  src={image.url}
                  alt={`Image ${image.id}`}
                  onClick={() => handleImageClick(image.id)}
                  style={{
                    width: image.isVisible ? '250px' : '200px',
                    height: image.isVisible ? '250px' : '200px',
                    padding: '2%',
                    opacity: 1,
                    transition: 'width 0.5s, height 0.5s',
                    cursor: 'pointer',
                  }}
                />
                {image.isVisible && <p>{image.title}</p>}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Fenêtre modale */}
      {isModalOpen && selectedImageId !== null && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img
              src={images.find((image) => image.id === selectedImageId)?.url}
              alt={`Image ${selectedImageId}`}
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '10px',
              }}
            />
            <p>{images.find((image) => image.id === selectedImageId)?.description}</p>
            <button className="close-button" onClick={closeModal}>
              &#x2715;
            </button>
          </div>
        </div>
      )}
    </div>
    </main>
  );
};

export default AlarmeDetails;
