import React, { Fragment } from 'react';
import Service from "../components/sections/Service/Service";
import Carousel from "../components/sections/Carousel";
import Partenaires from '../components/sections/Partenaires';

const home = () => {
  return (
    <Fragment >
      <Carousel />
      <Service />
      <Partenaires />
    </Fragment>
  );
};

export default home;
