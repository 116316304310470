import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { motion } from 'framer-motion';

import Section from '../../HOC/Section';
import {FaShieldAlt} from 'react-icons/fa';

import { useNavigate } from 'react-router-dom';

const Service = () => {
  const serviceBoxVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };
  const navigate = useNavigate();

  // Fonction pour gérer la navigation vers la page CablesDetails
  const handleCablesClick = () => {
    navigate('/CablesDetails');
  };
  const handleAppareillagesClick = () => {
    navigate('/AppareillagesDetails');
  };
  const handleEclairageClick = () => {
    navigate('/EclairageDetails');
  };
  const handleClimatisationClick = () => {
    navigate('/ClimatisationDetails');
  };
  const handleVideoClick = () => {
    navigate('/VideoDetails');
  };
  const handleAlarmeClick = () => {
    navigate('/AlarmeDetails');
  };

  return (
    <Section id='services'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Nos </span>Services
          </h3>
          <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={200} duration={2}>
          <h6 className='section-subtitle mx-auto ml-auto'>
          
          Nous sommes une entreprise spécialisée dans la fourniture électrique de qualité dans divers domaines. 
          Que ce soit pour vos besoins en câbles, en appareillages électriques, en éclairage, 
          en climatisation, en vidéo surveillance ou en systèmes d'alarme, nous sommes là pour vous aider. 
          Notre équipe expérimentée est dédiée à fournir des solutions adaptées à vos besoins, 
          en veillant à votre satisfaction et à votre tranquillité d'esprit.
          </h6>
          </ScrollAnimation>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-md-6 col-lg-4 mb-3'>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={300} duration={7}>
              <motion.div
              variants={serviceBoxVariants}
              initial='hidden'
              animate='visible'
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleCablesClick}
                  className='service-box d-flex'style={{ cursor: 'pointer' }}>
                <div className='service-icon mr-4'>
                <i className='fas fa-bolt' />
                  
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Câbles</h5>
                  <p className='service-description'>
                  Nous fournissons une large gamme de câbles de haute qualité pour 
                  répondre à vos besoins en matière de connectivité. 
                  Que ce soit pour des applications résidentielles, commerciales ou industrielles, 
                  nos câbles sont conçus pour assurer des performances fiables et durables.
                  </p>
                </div>
              </motion.div>
              </ScrollAnimation>
            </div>
            <div className='col-md-6 col-lg-4 mb-3'>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={400} duration={8}>
              <motion.div
              variants={serviceBoxVariants}
              initial='hidden'
              animate='visible'
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleAppareillagesClick}
               className='service-box d-flex'style={{ cursor: 'pointer' }}>
                <div className='service-icon mr-4'>
                <i className='fas fa-plug' />
                  
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Appareillages & Modulaires</h5>
                  <p className='service-description'>
                  Nous proposons une sélection d'appareillages de qualité pour répondre à vos besoins 
                  en matière d'équipements électriques. Que vous ayez besoin de prises, d'interrupteurs, 
                  de disjoncteurs ou d'autres appareils électriques, nous avons ce qu'il vous faut.
                  </p>
                </div>
              </motion.div>
              </ScrollAnimation>
            </div>
            <div className='col-md-6 col-lg-4 mb-3'>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={500} duration={9}>
              <motion.div
              variants={serviceBoxVariants}
              initial='hidden'
              animate='visible'
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleEclairageClick}
              className='service-box d-flex'style={{ cursor: 'pointer' }}>
                <div className='service-icon mr-4'>
                <i className='fas fa-lightbulb' />
                  
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Éclairage</h5>
                  <p className='service-description'>
                  Nous proposons des solutions d'éclairage innovantes et efficaces pour créer l'ambiance parfaite dans votre espace. 
                  Que vous recherchiez un éclairage intérieur ou extérieur, nous avons une large gamme de luminaires et de systèmes 
                  d'éclairage pour répondre à vos besoins.
                  </p>
                </div>
              </motion.div>
              </ScrollAnimation>
            </div>
            <div className='col-md-6 col-lg-4 mb-3'>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={600} duration={10}>
              <motion.div
              variants={serviceBoxVariants}
              initial='hidden'
              animate='visible'
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleClimatisationClick} 
              className='service-box d-flex'style={{ cursor: 'pointer' }}>
                <div className='service-icon mr-4'>
                <i className='fas fa-snowflake' />
                  
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Climatisation</h5>
                  <p className='service-description'>
                  Vous cherchez une climatisation silencieuse et efficace, nous avons votre solution.
                  Un bon système de climatisation vous assure confort et économie. 
                  Nos experts vous donnent des conseils et assurent votre tranquillité d’esprit.
                  </p>
                </div>
              </motion.div>
              </ScrollAnimation>
            </div>
            <div className='col-md-6 col-lg-4 mb-3'>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={700} duration={11}>
              <motion.div
              variants={serviceBoxVariants}
              initial='hidden'
              animate='visible'
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleVideoClick} 
              className='service-box d-flex'style={{ cursor: 'pointer' }}>
                <div className='service-icon mr-4'>
                <i className='fa fa-video' />
                  
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Vidéo Surveillance</h5>
                  <p className='service-description'>
                  Nous proposons des solutions de vidéo surveillance avancées pour protéger votre propriété et assurer votre sécurité. 
                  Nos systèmes de surveillance vous permettent de surveiller en temps réel et d'enregistrer des vidéos pour une 
                  surveillance efficace et une tranquillité d'esprit.
                  </p>
                </div>
              </motion.div>
              </ScrollAnimation>
            </div>
            <div className='col-md-6 col-lg-4 mb-3'>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={800} duration={12}>
              <motion.div
              variants={serviceBoxVariants}
              initial='hidden'
              animate='visible'
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleAlarmeClick} 
              className='service-box d-flex'style={{ cursor: 'pointer' }}>
                <div className='service-icon mr-4'>
                  <FaShieldAlt />
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Alarme</h5>
                  <p className='service-description'>
                  Nous offrons des systèmes d'alarme de haute qualité pour sécuriser votre domicile ou votre entreprise. 
                  Nos systèmes d'alarme sont dotés de fonctionnalités avancées telles que la détection de mouvement, 
                  les capteurs de porte et de fenêtre, et la notification d'alerte pour vous protéger contre les intrusions.
                  </p>
                </div>
              </motion.div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Service;
