import React from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import bgImage from "../assets/img/blurred-image-1.jpg";

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    emailjs.send(
      "service_e0hr4z5",
      "template_r64tm5b",
      {
        subject: data.subject,
        name: data.name,
        lastname: data.lastname,
        company: data.company,
        phone: data.phone,
        email: data.email,
        message: data.message,
      },
      "1xQtduIT5CLSugC_O"
    );
    alert("Votre message a bien été envoyé !");
    
    // Réinitialiser les champs du formulaire après le traitement
    reset();
  };
  
  return (
    <div className="container"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="row">
        <div className="col-lg-6 col-md-6">
          <div className="section-header pt-5 pb-5 text-center">
            <h3 className="section-title">
              <span>Nous </span>Contacter
            </h3>
            <h6 className="section-subtitle mx-auto">
              N'hésitez pas à nous contacter pour toute demande, commentaire ou question que vous pourriez avoir.
            </h6>
          </div>
          <form className="mb-5" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <label className="block-label" htmlFor="subject">
                Sujet
              </label>
              <input
                {...register("subject")}
                className="form-control"
                placeholder="Votre Sujet ..."
                type="text"
                id="subject"
                required
              />
              {errors.subject && <p className="text-[red]">Indiquez le sujet de votre mail.</p>}
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="name">
                Nom
              </label>
              <input
                {...register("name", { required: true })}
                className="form-control"
                placeholder="Votre Nom ..."
                type="text"
                id="name"
                required
              />
              {errors.name && <p className="text-[red]">Nom requis</p>}
              <label className="form-label" htmlFor="lastname">
                Prénom
              </label>
              <input
                {...register("lastname", { required: true })}
                className="form-control"
                placeholder="Votre Prénom ..."
                type="text"
                id="lastname"
                required
              />
              {errors.lastname && <p className="text-[red]">Prénom requis</p>}
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="company">
                Société
              </label>
              <input
                {...register("company")}
                className="form-control"
                placeholder="Nom de votre Société..."
                type="text"
                id="company"
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="phone">
                N° Téléphone
              </label>
              <input
                {...register("phone", { required: true })}
                className="form-control"
                placeholder="Votre Numéro ..."
                type="text"
                id="phone"
                required
              />
              {errors.phone && <p className="text-[red]">N° de téléphone requis</p>}
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="email">
                Email
              </label>
              <input
                {...register("email", { required: true })}
                className="form-control"
                placeholder="Votre Email ..."
                type="email"
                id="email"
                required
              />
              {errors.email && <p className="text-[red]">Email requis</p>}
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="message">
                Message
              </label>
              <textarea
                {...register("message")}
                className="form-control"
                style={{ height: "200px" }}
                placeholder="Votre message ..."
                id="message"
                required
              />
              <button className="btn btn-danger mt-5" type="submit">
                Envoyer
              </button>
            </div>
          </form>
        </div>
        <div className="col-lg-6 col-md-6">
        </div>
      </div>
    </div>
  );
};

export default Contact;
